body {
  background-color: #FAFAF4;
  font-family: 'PT Serif', serif;
  height:100%;
}

.card {
  margin: 10%;
}

.text-muted {
  margin-left: 1%;
}

.terms {
  text-align: left !important;
}

.legal {
  color: #1F4D2F;
  margin: 10px;
  font-size: 10px;
}

span.merci {
    font-weight: bold;
    font-size: 20px;

}

.download {
  text-align: center;
}

span.subtitle {
  margin-top: -20px;
  font-size: 12px;
  
}

span.conditions {
  margin-top: -20px;
  font-size: 12px;
}


.btn-vert {
  background-color: #1F4D2F;
  border-color: #1F4D2F;
  color: white;
}

.btn-vert {
  background-color: #1F4D2F;
  border-color: #1F4D2F;
  color: white;
}

.btn:hover {
  background-color: rgb(50, 95, 66);
  border-color: #1F4D2F;
  color: white !important;
}

.btn-vert:active:focus {
  background-color: #1F4D2F;
  border-color: #1F4D2F;
  color: white !important;
}

.img-logo {
  max-width: 100%;
  width: 400px;
  text-align: center;
  margin-bottom: 5%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}


.login-label {
  text-align: left !important;
}

.prez-container {
  position: relative;
  display: inline-block;
  width: 90%;
  height: 20%;
  background-color: teal;
}

.visio {
  text-align: center;
}

.visio-2 {
  display: inline-block;
}

.nav-logo {
  max-width: 200px;
}

.buttons {
  position: relative;
  right: 0;
}

.btn {
  margin: 10px;
}

.btn-outline-success {
  border-color: #1F4D2F;
  color: #1F4D2F;
}

.lefty {
  position: relative;
  right: 0 !important;
}

.public-pass {
  display: flex;
  justify-content: center;
  align-items: center;
}